<template>
  <div class="container">
    <el-form :model="form" :rules="rules" ref="otherForm" label-width="140px" @submit.prevent="submit('otherForm')"
             :close-on-click-modal="false">
      <el-row>
        <el-col :span="12">
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类型" prop="type_id">
            <el-select v-model="form.type_id" placeholder="请选择">
              <template v-for="item in typelist">
                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="IP" prop="ip">
            <el-input v-model="form.ip"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="管理者" prop="manager_id">
            <el-select v-model="form.manager_id" placeholder="请选择">
              <template v-for="item in managerlist">
                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设备照片">
            <el-upload
                    class="avatar-uploader"
                    name="image_file"
                    :action="uploadUrl"
                    :data="{'m': 'device'}"
                    :headers="headerObj"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" ref="img" id="img1">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span style="color:#d0d0d0;">支持jpg，png格式照片，最大5M</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="位置">
            <el-cascader v-model="form.room_id" placeholder="请选择机房" :options="roomlist"
                         :props="{label: 'name', value: 'id' }" clearable style="width: 260px"></el-cascader>
            <p>{{form.gps}}</p>
            <p>{{roomGps}}</p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="品牌" prop="brand">
            <el-input v-model="form.brand" placeholder="请输入设备品牌"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="型号" prop="model_no">
            <el-input v-model="form.model_no" placeholder="请输入设备型号" style="width: 260px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="序列号" prop="sn_no">
            <el-input v-model="form.sn_no" placeholder="请输入设备序列号 "></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="固定资产编号" prop="asset_no">
            <el-input v-model="form.asset_no" placeholder="请输入固定资产编号" style="width: 260px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否为信创产品" prop="made_inchina">
            <el-radio-group v-model="form.made_inchina">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="价格" prop="price">
            <el-input v-model="form.price" placeholder="请输入价格" style="width: 260px"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="开始使用时间" prop="start_usetime">
            <el-date-picker
                    v-model="form.start_usetime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期" style="width:150px">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="维保到期时间" prop="main_time">
            <el-date-picker
                    v-model="form.main_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期" style="width:150px">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="位置" prop="room_id">-->
<!--            <el-cascader v-model="form.room_id" placeholder="请选择机房机柜" :options="roomlist"-->
<!--                         :props="{label: 'name', value: 'id' }" clearable style="width: 260px"></el-cascader>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="所属集群" prop="group_id">
            <el-select v-model="form.group_id" placeholder="请选择">
              <template v-for="item in grouplist">
                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="note">
        <el-input v-model="form.note" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <div style="margin-top:30px; text-align: center">
        <el-button type="primary" native-type="submit" @click.prevent="submit('otherForm')">提交</el-button>
        <el-button @click="closePop">关闭</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import EXIF from "exif-js";

  export default {
    name: "OtherForm",
    props: {
      id: {
        type: Number
      },
    },
    data() {
      return {
        form: {
          name: '',
          pic: '',
          gps: '',
          mac: '',
          ip: '',
          type_id: '',
          manager_id: '',
          room_id: '',
          note: '',
          brand: '',
          sn_no: '',
          model_no: '',
          asset_no: '',
          made_inchina: 1,
          start_usetime: null,
          price: '',
          main_time: null,
        },
        managerlist: [],
        roomlist: [],
        typelist: [],
        grouplist: [],
        firewall: [],
        exchange: [],
        uploadUrl: this.$api.BaseUrl + this.$api.UploadFile,
        headerObj: {
          'access-token': sessionStorage.getItem('access-token')
        },
        imageUrl: '',
        roomGps: '',
        rules: {
          name: [
            {required: true, message: '名称不能为空', trigger: 'blur'},
            {min: 2, max: 40, message: '长度2-40字符', trigger: 'blur'}
          ],
          ip: [
            {required: true, message: '请选择输入IP', trigger: 'blur'}
          ],
          type_id: [
            {required: true, message: '请选择类型', trigger: 'change'}
          ],
          manager_id: [
            {required: true, message: '请选择管理者', trigger: 'change'}
          ],
          room_id: [
            {required: true, message: '请选择位置', trigger: 'change'}
          ],
        },
      }
    },
    watch: {
      id(val, oldVal) {
        if (val != oldVal && val != null) {
          this.getInfo();
        }
        if (val === null) {
          this.form = {};
        }
      }
    },
    mounted() {
      if (this.id != null) {
        this.getInfo();
      }
    },
    created() {
      this.getTypeList();
      this.getManagerList();
      this.getRoomList();
      this.getGroupList();
      this.getFirewallList();
      this.getExchangeList();
    },
    methods: {
      getInfo() {
        this.$get(this.$api.GetDeviceInfo, {id: this.id, type: 'simple'}).then((res) => {
            this.form = res.data;
          this.imageUrl = res.data.pic;
        });
      },
      //机房列表
      getRoomList() {
        this.$get(this.$api.GetRoomOption).then((res) => {
            this.roomlist = res.data;
        });
      },
      getTypeList() {
        this.$get(this.$api.GetTypeList, {p_id: 3}).then((res) => {
          this.typelist = res;
        });
      },
      //Manager
      getManagerList() {
        this.$get(this.$api.GetManagerList).then((res) => {
            this.managerlist = res.data.list;
        });
      },
      //集群列表
      getGroupList() {
        this.$get(this.$api.GetGroupList).then((res) => {
            this.grouplist = res.data.list;
        });
      },
      reset() {
        this.form = {
          name: '',
          mac: '',
          type: '',
          type_id: '',
          room_id: [],
          ip: '',
          manager_id: '',
          note: '',
          brand: '',
          sn_no: '',
          model_no: '',
          asset_no: '',
          made_inchina: 1,
          start_usetime: null,
          price: '',
          main_time: null,
          pic: '',
          gps: '',
        };
        this.imageUrl = '';
        this.roomGps = '';
        //清空验证项
        this.$nextTick(() => {
          this.$refs['otherForm'].clearValidate();
        });
        if (this.$refs['otherForm']) {
          this.$refs['otherForm'].resetFields();
        }
      },
      closePop(param) {
        this.reset();
        this.$emit('closePop', param);
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.form.pic = res.data.filename;
      },
      beforeAvatarUpload(file) {
        if (file.size / 1024 / 1024 > 10) {
          this.$message.error('上传头像图片大小不能超过 10MB!');
          return false;
        }

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          // 在上传前使用 exif-js 获取文件信息
          this.getExifData(file);
          return;
        } else {
          this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
          return false;
        }
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.type = 3; //其他设备
            let postUrl = this.$api.AddDevice;
            if (this.id != null) {
              postUrl = this.$api.EditDevice;
            }
            this.$post(postUrl, this.form).then(() => {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                });
                this.closePop('refresh');
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 获取照片信息-经纬度
      getExifData(file) {
        // 读取图片的 EXIF 信息
        let _this = this;
        EXIF.getData(file, function() {
          const exifData = EXIF.getAllTags(this);
          // 处理 EXIF 数据，例如提取经纬度信息等
          const imgLat = exifData.GPSLatitude;
          const imgLon = exifData.GPSLongitude;
          if(imgLat && imgLon){
            //计算出经纬度并保留6为小数
            const lon = (imgLon[0] + imgLon[1]/60 + imgLon[2]/60/60).toFixed(6)
            const lat = (imgLat[0] + imgLat[1]/60 + imgLat[2]/60/60).toFixed(6)
            _this.form.gps = lon + ',' + lat;
            console.log(lon, lat);
            //获取附近的机房
            _this.$get(_this.$api.GetNearRoom, {lon: lon, lat: lat}).then((res) => {
              console.log(res)
              _this.roomGps = '该设备距离机房较近：'+ res.data.name;
              _this.form.room_id = res.data.id;
            });
          }
        });
      },
    }
  }
</script>

<style scoped>
  .avatar-uploader {
    width: 180px;
    height: 100px;
    border: 1px dashed #1E75BD;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 180px;
    height: 100px;
  }
  }
  .avatar-uploader:hover {
    border-color: #409EFF;
  }
  /deep/ .el-form-item {
    margin-bottom: 20px;
  }
</style>
